<template>
  <ml-container>
        <v-dialog
          v-model="createAddress"
          max-width="556"
          >
              <div>
                <div class="tw-bg-white tw-space-y-4 tw-p-4 tw-leading-6">
                  <Address-form v-if="createAddress" :id="null" @submitted="store"></Address-form>
                </div>
              </div>
          </v-dialog>

        <v-dialog
          v-model="updateAddress"
          max-width="556"
          >
              <div>
                <div class="tw-bg-white tw-space-y-4 tw-p-4 tw-leading-6">

                  <Address-form :is-edit="true" :id="selectIdEditAddress"  @submitted="update"></Address-form>
                </div>
              </div>
          </v-dialog>
    <ml-grid class="sm:tw-gap-y-8 tw-grid tw-gap-y-6">
      <div
          class="tw-col-span-3 tw-rounded-xl tw-border-gray-n1 tw-border tw-p-9"
      >
        <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <span class="tw-font-semibold tw-mb-3">{{ $t('address.add_address') }}</span>
          <!--
          <router-link :to="{ name: 'address.create' }" class="tw-cursor-pointer tw-bg-black tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-text-white">
            <plus-icon class="tw-w-6 tw-w-6" />
          </router-link>
          -->

          <span @click="create()" class="tw-cursor-pointer tw-bg-black tw-rounded-xl tw-w-14 tw-h-14 tw-flex tw-items-center tw-justify-center tw-text-white">
            <plus-icon class="tw-w-6 tw-w-6" />
          </span>

        </div>

      </div>

      <div class="tw-col-span-3 tw-rounded-xl tw-border-gray-n1 tw-border tw-p-4" v-for="(item,index) in items" :key="index">
        <div class="tw-text-black">
          <div class="tw-flex tw-items-center">
            <div v-if="item.type_id">
              <div class=" tw-rounded tw-h-8 tw-w-30 tw-flex tw-items-center tw-justify-center"
              :class="getColor(item.type.code)"
              >
              <span class="tw-text-brown tw-text-xs tw-font-xs tw-ml-2 tw-mr-2 tw-font-semibold">{{ item.type.name }}</span>
              </div>
            </div>
            <div class="tw-ml-auto tw-flex tw-space-x-3">
              <div v-if="item.type">
              <span v-if="item.type.code != 'ha'" class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-red-100 tw-text-red tw-cursor-pointer"
                    @click="destroy(item)">
                <trash2-icon class="tw-w-4 tw-h-4" />
              </span>
              </div>
              <span class="tw-flex tw-items-center tw-justify-center tw-w-8 tw-h-8 tw-rounded-xl tw-border tw-border-gray-n1 tw-text-black tw-cursor-pointer"
                     @click="edit(item.id)">
                <edit2-icon class="tw-w-4 tw-h-4" />
              </span>
              <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                          class="tw-ml-auto"
                          v-bind="attrs"
                          v-on="on"
                      >
                        <check-circle-icon :class="item.valid ? 'tw-text-green':'tw-text-red'" class="tw-w-4 tw-h-4" stroke-width="1" />
                      </div>
                    </template>
                    <span v-if="item.valid">Verified!</span>
                    <span v-else>Not verified!</span>
              </v-tooltip>

            </div>
          </div>
          <div class="tw-text-sm tw-leading-relaxed tw-mt-3 tw-flex tw-flex-col">
            <span>{{ item.address1 }} {{ item.address2 }}</span>
            <span v-if="item.city && item.state">{{ item.state.name }}, {{ item.city.name }}, {{ item.postal_code }}</span>
            <span v-if="item.country">{{ item.country.name }}</span>
            <span>{{ item.phone }}</span>
            <span>{{ item.comment }}</span>
          </div>
          <!--
          <div class="tw-text-sm tw-leading-relaxed tw-mt-3 tw-flex tw-flex-col">
            <span v-if="item.default_address" class="tw-text-xs  tw-px-2  tw-inline-flex">
              {{ $t('address.primary') }}
            </span>
            <div v-else-if="item.type">
            <span v-if="item.type.code != 'ha'"
                  class="tw-cursor-pointer tw-rounded-xl tw-text-xs tw-px-2  tw-inline-flex"
                   @click="setDefault(item)">{{ $t('address.set_primary_address') }}
            </span>
            </div>
          </div>
          -->
        </div>
      </div>
    </ml-grid>

  </ml-container>
</template>

<script>

import MlContainer from "@/components/layout/MlContainer";
import MlGrid from "@/components/layout/MlGrid";
import { Edit2Icon, Trash2Icon, PlusIcon, CheckCircleIcon  } from "vue-feather-icons";
import AddressForm from '../../components/AddressForm.vue'

export default {
  components: {
    MlGrid,
    MlContainer,
    Edit2Icon,
    Trash2Icon,
    PlusIcon,
    CheckCircleIcon,
    AddressForm
  },
  middleware: 'user',

  data() {
    return {
      loading: false,
      items: [],
      createAddress: false,
      updateAddress: false,
      selectIdEditAddress: null,
      primaryAddress: null,
    }
  },

  mounted() {
    this.getAddress();

  },
  watch: {
    "$route": 'getAddress',
  },
  computed: {

  },
  methods: {
    getColor(code){
      if(code == 'ha'){
        return 'tw-bg-antiquewhite';
      }else if (code == 'sa'){
        return 'tw-bg-lightred';
      }else if (code == 'wva'){
        return 'tw-bg-green-100';
      }else{
        return 'tw-bg-gray-n1';
      }
    },
    returnPrimaryAddress(data){
      if(data){
        this.primaryAddress = data.filter(item => item.default_address === true)[0];
        this.$emit('selectedAddress', {
            item: this.primaryAddress
        });
      }
      return this.primaryAddress;
    },
    edit(id) {

      this.selectIdEditAddress = id;
      this.updateAddress = true;
      return true;
      //this.$router.push({name: 'address.edit', params: {id: id}})
    },
    create(){
      this.createAddress = true;
    },
    async store(data){

      this.createAddress = false;
      this.loading = true;
      let item = data.item;
      await this.$http.post('/api/address', item)
            .then(({ data }) => {
                this.$toast.success(data.message, "Success");
                this.getAddress();
            })
            .catch((error) => {
              this.$toast.warning(error.data.message, "Warning");
            })
      this.loading = false;
    },
   async update(data){
    this.updateAddress = false;
    this.loading = true;
    let item = data.item;
    await this.$http.patch('/api/address/' + item.id, item)
            .then(({ data }) => {
              this.getAddress();
              this.$toast.success(data.message, "Success");
            })
            .catch(( error ) => {
                this.getAddress();
                this.$toast.warning(error.data.message, "Warning");
            });
      this.loading = false;
    },
    delete(item) {

      let uri = '/api/address/' + item.id;
      this.$http.delete(uri)
          .then((response) => {
            this.getAddress();
            this.$toast.success(response.data.message, "Success");
          }).catch(error => {
        //console.log(error);
        this.$toast.warning(error.data.message, "Warning")
      });

    },
    destroy(item) {
      this.$toast.question('Are you sure about that?', "Confirm", {
        timeout: 20000,
        close: false,
        overlay: true,
        displayMode: 'once',
        id: 'address',
        zindex: 999,
        title: 'Hey',
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', (instance, toast) => {

            this.delete(item);

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }, true],
          ['<button>NO</button>', function (instance, toast) {

            instance.hide({transitionOut: 'fadeOut'}, toast, 'button');

          }],
        ]
      });
    },
    setDefault(item) {

      let uri = '/api/address/' + item.id;
      item.default_address = 1;
      item.country_id = item.country.id;
      /*
      let value = {
        id: item.id,
        default_address: 1
      }
      */
      this.$http.patch(uri, item)
          .then((response) => {
            this.getAddress();
            this.$toast.success(response.data.message, "Success");
          })
          .catch(() => {
            item.default_address = 0;
          });

    },
    getAddress() {

      this.loading = true;

      const params = {};
      this.$http.get('/api/address', {params})
          .then(({data}) => {
            this.items = data.data;
            this.returnPrimaryAddress(data.data);
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() =>
              this.loading = false,
          )
    },
  }
}
</script>
